document.addEventListener('DOMContentLoaded', function(event) {
    const cMainHeaderHamburger = document.querySelector('.c-main-header__hamburger');
    const cMainHeader = document.querySelector('.c-main-header');
    const headerLinks = document.querySelectorAll('.c-header-menu__list-item-link');
    const footerLinks = document.querySelectorAll('.c-footer-menu__list-item-link');
    const wpadminbar = document.querySelector('#wpadminbar');
    const body = document.body;

    var wpadmin = body.classList.contains('wp-admin');
    /*******************************************/
    /* RESIZE FUNCTIONS */
    let mq_sm = window.matchMedia('(max-width: 575px)');
    mq_sm.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_md = window.matchMedia('(min-width: 576px) and (max-width: 767px)');
    mq_md.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_mobileadminbar = window.matchMedia('screen and (max-width: 782px)');
    mq_mobileadminbar.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_standardadminbar = window.matchMedia('screen and (min-width: 783px)');
    mq_standardadminbar.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_lg = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    mq_lg.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_xl = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
    mq_xl.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_custom_xl =
        window.matchMedia('(min-width: 1200px) and (max-width: 1365px)');
    mq_custom_xl.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_xxl = window.matchMedia('(min-width: 1366px) and (max-width: 1399px)');
    mq_xxl.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    })
    let mq_xxxl = window.matchMedia('(min-width: 1400px)');
    mq_xxxl.addListener(function(mql) {
        if (mql.matches) {
            screenResize();
        }
    });
    /* END RESIZE FUNCTIONS */
    /*******************************************/

    function screenResize() {
        if (mq_lg.matches || mq_xl.matches || mq_custom_xl.matches ||
            mq_xxl.matches || mq_xxxl.matches) {
            body.classList.remove('mobile-menu-show');
            body.classList.remove('disable-scroll');
        }
    }


    if ((cMainHeaderHamburger != null) && (cMainHeader != null)) {
        cMainHeaderHamburger.addEventListener('click', (e) => {
            e.preventDefault();
            body.classList.toggle('mobile-menu-show');
            body.classList.toggle('disable-scroll');
        });
    }

    if (headerLinks.length > 0) {
        headerLinks.forEach((el) => {
            el.addEventListener('click', (e) => {
                body.classList.remove('mobile-menu-show');
                body.classList.remove('disable-scroll');
                let offset = cMainHeader.clientHeight;
                // e.preventDefault();
                const hash = e.currentTarget.getAttribute('href').split('#');
                const scrollTarget = document.querySelector('#' + hash[1]);

                if (!scrollTarget) return;

                if (wpadminbar) {
                    offset = offset + wpadminbar.clientHeight;
                }

                scrollToSmoothly(scrollTarget.offsetTop - offset, 150);
            });
        });
    }

    if (footerLinks.length > 0) {
        footerLinks.forEach((el) => {
            el.addEventListener('click', (e) => {
                let offset = cMainHeader.clientHeight;
                // e.preventDefault();
                const hash = e.currentTarget.getAttribute('href').split('#');
                const scrollTarget = document.querySelector('#' + hash[1]);

                if (!scrollTarget) return;

                if (wpadminbar) {
                    offset = offset + wpadminbar.clientHeight;
                }

                scrollToSmoothly(scrollTarget.offsetTop - offset, 150);
            });
        });
    }

    const links = document.querySelectorAll('.scrollable-link');

    scrollToSmoothly = (pos, time) => {
        const currentPos = window.pageYOffset;
        let start = null;
        if (time == null) time = 500;
        pos = +pos, time = +time;
        window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start;
            const progress = currentTime - start;
            if (currentPos < pos) {
                window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
            } else {
                window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
            }
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        });
    };

    if (links.length > 0) {
        links.forEach((el) => {
            el.addEventListener('click', (e) => {
                let offset = cMainHeader.clientHeight;
                e.preventDefault();
                const hash = e.currentTarget.getAttribute('href').split('#');
                const scrollTarget = document.querySelector('#' + hash[1]);

                if (!scrollTarget) return;

                if (wpadminbar) {
                    offset = offset + wpadminbar.clientHeight;
                }

                scrollToSmoothly(scrollTarget.offsetTop - offset, 150);
            });
        });
    }

    // Get all sections that have an ID defined
    const sections = document.querySelectorAll('.scrollable-section');

    if (sections.length > 0) {
        navHighlighter = () => {
            // Get current scroll position
            const scrollY = window.pageYOffset;

            // Now we loop through sections to get height, top and ID values for each
            sections.forEach(current => {
                let offset = cMainHeader.clientHeight;
                const sectionHeight = current.offsetHeight;

                if (wpadminbar) {
                    offset = offset + wpadminbar.clientHeight;
                }

                const sectionTop = current.offsetTop - offset - 1;
                sectionId = current.getAttribute('id');
                /*
                - If our current scroll position enters the space where current section on
                screen is, add .active class to corresponding navigation link, else remove
                it
                - To know which link needs an active class, we use sectionId variable we
                are getting while looping through sections as an selector
                */
                if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                    if (document.querySelector('.c-header-menu__list-item-link[href$=' + sectionId + ']')) {
                        document.querySelector('.c-header-menu__list-item-link[href$=' + sectionId + ']').classList.add('active');
                    }
                } else {
                    if (document.querySelector('.c-header-menu__list-item-link[href$=' + sectionId + ']')) {
                        document.querySelector('.c-header-menu__list-item-link[href$=' + sectionId + ']').classList.remove('active');
                    }
                }
            });
        };
        // Add an event listener listening for scroll
        window.addEventListener('scroll', navHighlighter);
    }

    const disabled_links = document.querySelectorAll('.c-button__button--disabled');

    if (disabled_links.length > 0) {
        disabled_links.forEach(disabled_link => {
            disabled_link.addEventListener('click', function(e) {
                e.preventDefault();
            });
        });
    }

    if (!wpadmin) {
        AOS.init();
        screenResize();
    }
    // } else {
    //     if (window.acf) {
    //         window.acf.addAction('render_block_preview', function() {
    //         });
    //     }
    // }
});